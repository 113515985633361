import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

IconText.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
};
IconText.defaultProps = {
  className: "mb-3",
};

function IconText(props) {
  return (
    <div className={`d-flex align-items-center ${props.className}`}>
      <FontAwesomeIcon icon={props.iconName} className="mr-3" />
      {props.children}
    </div>
  );
}

export default IconText;

import React from "react";
import { Helmet } from "react-helmet";
import Page from "../components/layout/page.component";
import Layout from "../components/layout/layout.component";
import Titantron from "../components/titantron/titantron.component";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import secondaryVideo from "../siteContent/secondaryPage/videos/secondaryVideo.mp4";
import StandardHeadline from "../components/content/elements/text/standardHeadline.component";
import StandardText from "../components/content/elements/text/standardText.component";
import IconText from "../components/content/elements/text/iconText.component";

export default function Kontakt() {
  return (
    <Page titlePrefix={"Kontakt"}>
      <Helmet>
        <meta
          name="description"
          content="Kontakt und Information zu Veranstaltungstechnik von Mollton."
        />
      </Helmet>
      <Layout background="dark" width="full">
        <Titantron
          height="half"
          title="Kontakt"
          underline="Anfrage, Information oder sonstige Angelegenheiten"
          video={secondaryVideo}
        />
      </Layout>
      <Layout
        background="trans"
        className="vh-100 justify-content-center"
        width="twothird"
      >
        <StandardHeadline
          headlineText={
            "Teilen Sie uns Ihr Anliegen mit und wir erstellen für Sie das passende Angebot! \bIhr Ansprechpartner ist Florian Moll."
          }
          className="text-center"
          type="h3"
        />
        <StandardText
          className="text-center"
          text="Direkt, per Telefon, Mail oder Facebook/WhatsApp Messenger!"
        />
        <div className="m-4 d-flex flex-column align-items-center">
          <div>
            <IconText iconName={faPhone}>
              <a href="tel:+49 176 97349268">+49 176 97349268</a>
            </IconText>
            <IconText iconName={faAt}>
              <a href="mailto:kontakt@mollton-veranstaltungstechnik.de">
                kontakt@mollton-veranstaltungstechnik.de
              </a>
            </IconText>
            <IconText iconName={faFacebook}>
              <a target="blank" href="https://www.facebook.com/molltontechnik">
                facebook.com/molltontechnik
              </a>
            </IconText>
          </div>
        </div>
      </Layout>
    </Page>
  );
}
